<!-- 设置中心-登录页设置 -->
<template>
    <div class="login-page-management">
        <table-data
            v-show="!fullScreenPreviewShow"
            id="table"
            ref="table"
            v-loading="tableLoading"
            :config="tableConfig"
            :tableData="tableData"
            @linkClick="handleTableLinkClick"
            @switchHandler="handleTableSwitchChange">
            <template v-slot:operation="slotData">
                <el-button
                    v-hasPermi="['schooltemplateconfig:edit']"
                    type="text"
                    @click="handleEdit(slotData.data)"
                >编辑</el-button>
                <el-button
                    v-hasPermi="['schooltemplateconfig:copy']"
                    type="text"
                    @click="handleCopy(slotData.data)"
                >复制</el-button>
                <el-button
                    v-if="slotData.data.templateType != '1'"
                    v-hasPermi="['schooltemplateconfig:del']"
                    type="text"
                    @click="handleDelete(slotData.data)"
                >删除</el-button>
            </template>
        </table-data>
        <pagination
            :total="total"
            :page.sync="listQuery.pageNum"
            :limit.sync="listQuery.pageRow"
            @pagination="getList"
        ></pagination>

        <!-- 编辑/复制弹窗 -->
        <dialog-wrapper
            v-if="detailDialogObj.dialogVisible"
            :dialogObj="detailDialogObj"
            @handleClose="handleDetailDialogClose">
            <div v-loading="detailDialogLoading">
                <detail-form
                    :form="detailForm"
                    :rules="detailFormRules"
                    :loginMethodOptions="loginMethodOptions"
                    :curCom="curCom"
                    @close="handleDetailDialogClose"
                    @saveJump="handleDetailSaveJump"
                    @save="handleDetailSave"
                ></detail-form>
            </div>
        </dialog-wrapper>

        <!-- 预览 -->
        <div v-if="fullScreenPreviewShow" class="preview">
            <preview-full-screen
                mode="view"
                :viewComparedStandardZoomRatio="fullScreenPreviewZoomRatio"
                :templateInfo="fullScreenPreviewTemplateInfo"
                :loginPageInfo="fullScreenPreviewLoginPageInfo"
                @close="handleFullScreenPreviewClose"
            ></preview-full-screen>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { Pagination, DialogWrapper, debounce } from "common-local"
import TableData from "@/components/scrollWrapper/Sub/TableData.vue"
import DetailForm from "@/components/scrollWrapper/LoginPageManagement/DetailForm.vue"
import PreviewFullScreen from '@/components/scrollWrapper/LoginPageTemplate/PreviewFullScreen.vue';
import { LoginPageManagementModel } from "@/models/LoginPageManagement.js";
import { standardWidth, defaultTheme, themeList } from "@/assets/data/LoginPageTemplate/themeList.js";
import onResize from "@/mixins/onResize"

export default {
    name: 'LoginPageManagement',
    mixins: [onResize],
    components: {
        TableData,
        Pagination,
        DialogWrapper,
        DetailForm,
        PreviewFullScreen
    },
    props: {
        params: {
            type: Object,
            default() {
                return {};
            }
        },
        curCom: {
            type: String,
            default () {
                return 'list'
            }
        }
    },
    data() {
        return {
            loginPageManagementModel: null,
            tableLoading: false,
            listQuery: {
                schoolId: '',
                pageNum: 1,
                pageRow: 20
            },
            excessHeight: 90,
            tableConfig: {
                thead: [
                    {
                        label: "模板名称",
                        prop: "templateName",
                        align: 'center',
                        type: "link",
                        // clickAuth: ['studentBase:documentStu']
                    },
                    {
                        label: "类型",
                        prop: "templateType",
                        align: 'center',
                        className: 'text-spacing-reduction',
                        type: "function",
                        callBack: (row) => {
                            return {
                                1: '系统内置',
                                2: '用户自定义'
                            }[+row.templateType]
                        }
                    },
                    {
                        label: "启用状态",
                        prop: "isUsed",
                        align: 'center',
                        type: 'switch',
                        // disabled: !hasPermission(['parentAddressBook:edit'])
                    },
                    {
                        label: "更新时间",
                        prop: "updateTime",
                        align: 'center',
                        type: "function",
                        callBack: (row) => {
                            return row.updateTime.slice(0, 10)
                        }
                    },
                    {
                        label: "操作",
                        labelWidth: "160px",
                        type: "slot",
                        slotName: "operation",
                        className: 'text-spacing-reduction'
                    }
                ],
                check: false,
                height: ''
            },
            tableData: [],
            total: 0,
            // 编辑/复制弹窗相关
            detailDialogObj: {
                title: '',
                dialogVisible: false,
                width: 'auto',
                mode: ''
            },
            detailDialogLoading: false,
            detailForm: {},
            detailFormRules: {
                templateName: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
                portalName: [{ required: true, message: "请输入门户名称", trigger: "blur" }],
                systemName: [{ required: true, message: "请输入系统登录名称", trigger: "blur" }],
                logo: [{ required: true, message: "请上传logo", trigger: "change" }],
                rememberme: [{ required: true, message: "请设置是否允许记住登录状态", trigger: "change" }],
                loginMethod: [{ type: 'array', required: true, message: '请至少选择一种登录方式', trigger: 'change' }],
                defaultLoginMethod: [{ required: true, message: "请选择默认登录方式", trigger: "change" }]
            },
            loginMethodOptions: [
                {
                    label: '账号密码',
                    value: '1'
                },
                {
                    label: '微信登录',
                    value: '2'
                },
                {
                    label: '钉钉登录',
                    value: '3'
                },
                {
                    label: '验证码',
                    value: '4'
                }
            ],
            // 预览相关
            standardWidth,
            defaultTheme,
            themeList,
            fullScreenPreviewShow: false,
            fullScreenPreviewWidth: 0,
            fullScreenPreviewZoomRatio: 1,
            fullScreenPreviewTemplateInfo: {},
            fullScreenPreviewLoginPageInfo: {}
        }
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId
        })
    },
    watch: {},
    created() {
        this.init()
    },
    mounted() {
        window.addEventListener('resize', this.initResizeEvent)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.initResizeEvent)
    },
    methods: {
        /**
         * 初始化相关
         * init 初始化入口
         * initVariable 初始化变量
         * handleRes 接口返回数据统一处理
         * getList 获取列表
         */
        // 初始化入口
        init() {
            this.initVariable()
            this.getList('init')
            if (this.params.templateId) {
                this.handleEdit({
                    id: this.params.templateId
                },'template')
            }
        },
        // 初始化变量
        initVariable() {
            this.loginPageManagementModel = new LoginPageManagementModel();
            this.listQuery.schoolId = this.schoolId
        },
        // 接口返回数据统一处理
        handleRes(res, fn) {
            if (res && res.data) {
                if (res.data.code == '200') {
                    fn()
                } else if (res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            }
        },
        // 获取列表
        async getList(mode) {
            this.tableLoading = true
            if (mode === 'init') {
                this.listQuery.pageNum = 1
                this.tableData = []
            }
            let res = await this.loginPageManagementModel.getLoginPageTemplateList(this.listQuery)
            console.log("[getList]res:", res);
            this.handleRes(res, () => {
                this.tableData = res.data.data.list
            })
            this.tableLoading = false
        },

        /**
         * 窗口大小改变事件
         * initResizeEvent 窗口大小改变事件入口
         * handleFullScreenPreviewBackgroundResize 窗口大小改变全屏预览背景大小改变
         * handleFullScreenPreviewResize 窗口大小改变重新计算全屏预览布局
         */
        // 窗口大小改变事件入口
        initResizeEvent() {
            // 如果全屏预览开着，重新计算全屏预览布局
            if (this.fullScreenPreviewShow) {
                this.handleFullScreenPreviewBackgroundResize()
                this.handleFullScreenPreviewResize()
            }
        },
        // 窗口大小改变全屏预览背景大小改变
        handleFullScreenPreviewBackgroundResize() {
            let fullScreenWidth = window.innerWidth
            let fullScreenHeight = window.innerHeight
            let dom = document.getElementById('preview_view_view')
            dom.style.width = fullScreenWidth + 'px'
            dom.style.height = fullScreenHeight + 'px'
        },
        // 窗口大小改变重新计算全屏预览布局
        handleFullScreenPreviewResize: debounce(function () {
            let fullScreenWidth = window.innerWidth
            let fullScreenHeight = window.innerHeight
            // console.log("[handleFullScreenPreviewResize]fullScreenWidth:", fullScreenWidth, "fullScreenHeight:", fullScreenHeight)
            // 计算整体视图宽度相较于标准宽度的缩放比率
            this.fullScreenPreviewZoomRatio = fullScreenWidth / this.standardWidth
            // console.log("[handleFullScreenPreviewResize]this.fullScreenPreviewZoomRatio:", this.fullScreenPreviewZoomRatio)
            // 开始处理全屏预览模板数据
            let loginPageInfo = this._.cloneDeep(this.fullScreenPreviewLoginPageInfo)
            // 计算全屏预览拖拽后宽度相较于拖拽前宽度的缩放比例
            let zoomRatio = fullScreenWidth / this.fullScreenPreviewWidth
            // 处理登录框数据
            Object.assign(loginPageInfo.loginBox, {
                top: Math.round(loginPageInfo.loginBox.top * zoomRatio),
                left: Math.round(loginPageInfo.loginBox.left * zoomRatio),
                width: Math.round(loginPageInfo.loginBox.width * zoomRatio),
                height: Math.round(loginPageInfo.loginBox.height * zoomRatio),
                timestamp: +new Date()
            })
            // 处理组件数据
            for (let comp of loginPageInfo.comps) {
                Object.assign(comp, {
                    top: Math.round(comp.top * zoomRatio),
                    left: Math.round(comp.left * zoomRatio),
                    width: Math.round(comp.width * zoomRatio),
                    height: Math.round(comp.height * zoomRatio),
                    timestamp: +new Date()
                })
                if (comp.type === 'Text') {
                    comp.fontSize = Math.round(comp.fontSize * zoomRatio)
                }
            }
            let dom = document.getElementById('preview_view_view')
            dom.style.width = fullScreenWidth + 'px'
            dom.style.height = fullScreenHeight + 'px'
            this.fullScreenPreviewWidth = fullScreenWidth
            this.fullScreenPreviewLoginPageInfo = loginPageInfo
        }, 200),

        /**
         * 操作按钮相关
         * handleTableLinkClick 表格link点击事件
         * handleTableSwitchChange 表格switch切换事件
         * handleTemplateEnable 启用模板
         * getTemplateDetail 获取模板详情
         * handleEdit 编辑
         * handleCopy 复制
         * handleDelete 删除
         */
        // 表格link点击事件
        handleTableLinkClick(row, tableItem) {
            if (tableItem.prop === 'templateName') { // 模板名称
                this.handleFullScreenPreview(row) // 预览
            }
        },
        // 表格switch切换事件
        handleTableSwitchChange(row, tableItem) {
            console.log("[handleTableSwitchChange]row:", row, "tableItem:", tableItem);
            if (tableItem.prop === 'isUsed') { // 启用状态
                if (row.isUsed == '1') {
                    this.handleTemplateEnable(row)
                } else {
                    row.isUsed = '1'
                    this.$message.warning('必须有一个启用模板')
                }
            }
        },
        // 启用模板
        async handleTemplateEnable(row) {
            let res = await this.loginPageManagementModel.setLoginPageTemplateEnabled({
                id: row.id,
                schoolId: this.schoolId
            })
            this.handleRes(res, () => {
                // this.$message.success("已启用")
                this.$message.success(res.data.msg)
                for (let rowData of this.tableData) {
                    if (rowData.id != row.id) {
                        rowData.isUsed = '0'
                    }
                }
            })
        },
        // 获取模板详情
        async getTemplateDetail(row) {
            this.detailDialogLoading = true
            let res = await this.loginPageManagementModel.getLoginPageTemplateDetail({
                id: row.id
            })
            console.log("[getTemplateDetail]res:", res);
            this.handleRes(res, () => {
                res.data.data.loginMethod = res.data.data.loginMethod.split(',')
                if (this.detailDialogObj.mode === 'copy') {
                    res.data.data.templateName += '（复制）'
                    res.data.data.templateType = '2'
                }
                this.detailForm = res.data.data
                console.log("[getTemplateDetail]this.detailForm:", this.detailForm);
                this.detailDialogLoading = false
            })
        },
        // 编辑
        handleEdit(row, type = 'list') {
            this.$emit('changeCurCom', type)
            this.detailDialogObj.mode = 'edit'
            this.detailDialogObj.title = '编辑'
            this.getTemplateDetail(row)
            this.handleDetailDialogShow()

        },
        // 复制
        handleCopy(row) {
            this.detailDialogObj.mode = 'copy'
            this.detailDialogObj.title = '复制'
            this.getTemplateDetail(row)
            this.handleDetailDialogShow()
        },
        // 删除
        handleDelete(row) {
            if (row.isUsed == '1') {
                this.$message.warning('模板正在使用中，不可删除！')
                return
            }
            this.$confirm('此操作将永久删除该模板, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.loginPageManagementModel.deleteLoginPageTemplate({
                    id: row.id,
                }).then(res => {
                    this.handleRes(res, () => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.getList()
                    })
                })
            })
        },

        /**
         * 编辑/复制弹窗相关
         * detailDialogObj 编辑/复制弹窗显示
         * handleDetailDialogClose 编辑/复制弹窗关闭
         * interfaceDetailSave 调编辑/复制接口
         * handleDetailSaveJump 编辑/复制保存后跳转页面
         * handleDetailSave 编辑/复制保存
         */
        // 编辑/复制弹窗显示
        handleDetailDialogShow() {
            this.detailDialogObj.dialogVisible = true
        },
        // 编辑/复制弹窗关闭
        handleDetailDialogClose() {
            this.detailDialogObj.dialogVisible = false
        },
        // 调编辑/复制接口
        async interfaceDetailSave() {
            let detailForm = this._.cloneDeep(this.detailForm)
            detailForm.loginMethod = detailForm.loginMethod.join(',')
            let res
            if (this.detailDialogObj.mode === 'copy') { // 复制
                res = await this.loginPageManagementModel.copyLoginPageTemplate(detailForm)
            } else if (this.detailDialogObj.mode === 'edit') { // 编辑
                res = await this.loginPageManagementModel.editLoginPageTemplate(detailForm)
            }
            console.log("[interfaceDetailSave]res:", res)
            return res
        },
        // 编辑/复制保存后跳转页面
        async handleDetailSaveJump() {
            let res = await this.interfaceDetailSave()
            this.handleRes(res, () => {
                this.handleDetailDialogClose()
                this.$emit('compSwitch', 'template', {
                    templateId: this.detailDialogObj.mode === 'copy' ? res.data.data : this.detailForm.id
                });
            })
        },
        // 编辑/复制保存
        async handleDetailSave() {
            let res = await this.interfaceDetailSave()
            this.handleRes(res, () => {
                this.$message.success(`${this.detailDialogObj.title}成功`)
                this.handleDetailDialogClose()
                this.detailForm.mode === 'copy' ? this.getList('init') : this.getList()
            })
        },

        /**
         * 预览相关
         * handleFullScreenPreview 预览打开
         * handleFullScreenPreviewClose 预览关闭
         */
        // 预览打开
        handleFullScreenPreview(row) {
            console.log("[handleFullScreenPreview]row:", row)
            let templateInfo = this._.cloneDeep(row)
            templateInfo.loginMethod = templateInfo.loginMethod.split(',')
            this.fullScreenPreviewTemplateInfo = templateInfo
            let fullScreenWidth = window.innerWidth
            let fullScreenHeight = window.innerHeight
            console.log("[handleFullScreenPreview]fullScreenWidth:", fullScreenWidth, "fullScreenHeight:", fullScreenHeight)
            // 记录全屏预览宽度
            this.fullScreenPreviewWidth = fullScreenWidth
            // 计算整体视图宽度相较于标准宽度的缩放比率
            let zoomRatio = fullScreenWidth / this.standardWidth
            this.fullScreenPreviewZoomRatio = zoomRatio
            console.log("[handleFullScreenPreview]this.fullScreenPreviewZoomRatio:", this.fullScreenPreviewZoomRatio)
            // 处理模板数据
            let loginPageInfo = JSON.parse(this.fullScreenPreviewTemplateInfo.loginPageInfo)
            // 防止初始化模板数据不规范
            if (!loginPageInfo.theme) {
                let theme = this.themeList[this.defaultTheme]
                // 主题
                this.$set(loginPageInfo, "theme", theme.id)
                // 背景
                let background = this._.cloneDeep(theme.background)
                this.$set(loginPageInfo, "background", background)
                // 登录框
                let loginBox = this._.cloneDeep(theme.loginBox)
                this.$set(loginPageInfo, "loginBox", loginBox)
                // 组件
                this.$set(loginPageInfo, "comps", [])
            }
            // 处理登录框数据
            if (loginPageInfo.loginBox) {
                Object.assign(loginPageInfo.loginBox, {
                    top: Math.round(loginPageInfo.loginBox.top * zoomRatio),
                    left: Math.round(loginPageInfo.loginBox.left * zoomRatio),
                    width: Math.round(loginPageInfo.loginBox.width * zoomRatio),
                    height: Math.round(loginPageInfo.loginBox.height * zoomRatio),
                    timestamp: +new Date()
                })
            }
            // 处理组件数据
            if (loginPageInfo.comps) {
                for (let comp of loginPageInfo.comps) {
                    Object.assign(comp, {
                        top: Math.round(comp.top * zoomRatio),
                        left: Math.round(comp.left * zoomRatio),
                        width: Math.round(comp.width * zoomRatio),
                        height: Math.round(comp.height * zoomRatio),
                        timestamp: +new Date()
                    })
                    if (comp.type === 'Text') {
                        comp.fontSize = Math.round(comp.fontSize * zoomRatio)
                    }
                }
            }
            this.fullScreenPreviewLoginPageInfo = loginPageInfo
            this.fullScreenPreviewShow = true
            this.$nextTick(() => {
                let dom = document.getElementById('preview_view_view')
                dom.style.width = fullScreenWidth + 'px'
                dom.style.height = fullScreenHeight + 'px'
            })
        },
        // 预览关闭
        handleFullScreenPreviewClose() {
            this.fullScreenPreviewShow = false
            this.$_resizeListener()
        }
    }
}
</script>

<style lang="scss" scoped>
.login-page-management {
    height: 100%;
    margin-right: 10px;
}
</style>
