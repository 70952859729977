<template>
    <div class="detail-form">
        <el-form
            ref="detailForm"
            class="common-form"
            :model="form"
            :rules="rules"
            label-width="120px">
            <el-form-item label="模板名称" prop="templateName">
                <el-input
                    class="input-width-medium"
                    v-model="form.templateName"
                    placeholder="请输入模板名称"
                    maxlength="20"
                    show-word-limit
                ></el-input>
            </el-form-item>

            <el-form-item label="门户名称" prop="portalName">
                <el-input
                    class="input-width-medium"
                    v-model="form.portalName"
                    placeholder="不填写默认展示 “企业综合管理系统”"
                    maxlength="20"
                    show-word-limit
                ></el-input>
            </el-form-item>

            <!-- <el-form-item label="系统登录名称" prop="systemName">
                <el-input
                    class="input-width-medium"
                    v-model="form.systemName"
                    placeholder="展示在登录页，不填写默认展示 “企业综合管理系统”"
                    maxlength="20"
                    show-word-limit
                ></el-input>
            </el-form-item> -->

            <el-form-item label="企业logo" prop="logo">
                <el-upload
                    class="logo-uploader"
                    :action="action"
                    :headers="headers"
                    :show-file-list="false"
                    accept="image/*"
                    :before-upload="beforeLogoUpload"
                    :on-success="handleLogoSuccess">
                    <div class="logo-uploader-logo" v-if="form.logo && form.logo.startsWith('http')">
                        <img class="logo-uploader-logo-img" :src="form.logo" alt="logo">
                        <div class="logo-uploader-logo-del" @click.stop="handleLogoDelete">
                            <i class="el-icon-delete"></i>
                        </div>
                    </div>
                    <div v-else class="logo-uploader-btn">
                        <i class="el-icon-plus logo-uploader-icon"></i>
                        <span class="logo-uploader-label">上传</span>
                    </div>
                </el-upload>
                <div class="logo-tips">
                    <div class="logo-tip">1.图片不允许涉及政治敏感与色情</div>
                    <div class="logo-tip">2.不超过2M，支持png、jpg等图片格式的文件</div>
                </div>
            </el-form-item>

            <el-form-item label="允许记住登录状态" prop="rememberme" class="labelSty">
                <el-switch
                    v-model="form.rememberme"
                    active-value="1"
                    inactive-value="0"
                ></el-switch>
            </el-form-item>

            <el-form-item label="登录方式" prop="loginMethod">
                <el-checkbox-group
                    v-if="form.loginMethod"
                    v-model="form.loginMethod"
                    @change="handleLoginMethodChange">
                    <el-checkbox
                        v-for="item in loginMethodOptions"
                        :key="item.value"
                        :label="item.value"
                    >{{ item.label }}</el-checkbox>
                </el-checkbox-group>
                <div class="loginMethod-tip">至少选择一种登录方式</div>
            </el-form-item>

            <el-form-item label="默认登录方式" prop="defaultLoginMethod">
                <el-radio-group v-if="form.loginMethod" v-model="form.defaultLoginMethod">
                    <template v-for="item in loginMethodOptions">
                        <el-radio
                            v-if="form.loginMethod.includes(item.value)"
                            :key="item.value"
                            :label="item.value"
                        >{{ item.label }}</el-radio>
                    </template>
                </el-radio-group>
            </el-form-item>
        </el-form>

        <div style="text-align: right;">
            <el-button v-if="curCom === 'list'" @click="handleClose">取消</el-button>
            <el-button type="primary" @click="handleSaveJump">保存并进入页面设置</el-button>
            <el-button v-if="curCom === 'list'" type="primary" @click="handleSave">保存</el-button>
        </div>
    </div>
</template>

<script>
import { getToken } from "@/libs/auth";

export default {
    name: 'DetailForm',
    components: {},
    props: {
        form: {
            type: Object
        },
        rules: {
            type: Object
        },
        loginMethodOptions: {
            type: Array
        },
        curCom: {
            type: String
        }
    },
    data() {
        return {

        }
    },
    filters: {},
    computed: {
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    created() {
    },
    mounted() {},
    methods: {
        beforeLogoUpload(file) {
            const isImage = file.type.split("/")[0] === "image";
            const isLt2M = file.size / 1024 / 1024 < 2;
            const is50 = file.name.length < 50;
            if (!is50) {
                this.$message.error("上传图片名称不能超过50个字符!");
                return false;
            }
            if (!isImage) {
                this.$message.error("只允许上传图片!");
                return false;
            }
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
                return false;
            }
        },
        handleLogoSuccess(res, file, fileList) {
            console.log("[handleLogoSuccess]res:", res);
            if (res.code == '200') {
                this.form.logo = res.data.url
            }
        },
        handleLogoDelete() {
            this.form.logo = ''
        },
        handleLoginMethodChange(data) {
            console.log("[handleLoginMethodChange]data:", data);
            data.sort()
            if (!data.includes(this.form.defaultLoginMethod)) {
                this.form.defaultLoginMethod = data[0] ? data[0] : ''
            }
        },
        handleClose() {
            this.$emit('close')
        },
        handleSaveJump() {
            this.$refs.detailForm.validate((valid) => {
                if (valid) {
                    this.$emit('saveJump')
                }
            })
        },
        handleSave() {
            this.$refs.detailForm.validate((valid) => {
                if (valid) {
                    this.$emit('save')
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
$logo-uploader-height: 80px;

.logo-uploader {
    width: $logo-uploader-height;
    height: $logo-uploader-height;

    .logo-uploader-logo {
        position: relative;
        width: $logo-uploader-height;
        height: $logo-uploader-height;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;

        .logo-uploader-logo-img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: inline-block;
        }

        .logo-uploader-logo-del {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 24px;
            background: #3C7FFF;
            border-radius: 6px 6px 0px 0px;
            text-align: center;
            line-height: 24px;
            color: #FFFFFF;
            font-size: 17px;
        }
    }

    .logo-uploader-btn {
        width: $logo-uploader-height;
        height: $logo-uploader-height;
        background: #F7F8FA;
        border: 1px dashed #CCCCCC;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .logo-uploader-icon {
            font-size: 18px;
        }

        .logo-uploader-label {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 1;
            margin-top: 6px;
        }
    }
}

.logo-tips {
    margin-top: 12px;

    .logo-tip {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 1;

        +.logo-tip {
            margin-top: 8px;
        }
    }
}

.loginMethod-tip {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 1;
    margin-top: -6px;
}
.labelSty{
    ::v-deep .el-form-item__label{
        line-height: 20px!important;
    }
}
</style>
